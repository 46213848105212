import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "focus-visible/dist/focus-visible.js"

// Components
import Img from "gatsby-image/withIEPolyfill"
import Hero from "../../components/hero"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import Stat from "../../components/stat"

// Assets
import activeStakeImage from "../../images/active-stake.svg"
import commonVisionImage from "../../images/common-vision.svg"
import longTermCommitmentImage from "../../images/long-term-commitment.svg"
import iconLinkedin from "../../images/linkedin.svg"
import presentationImage from "../../images/presentation.svg"

const IndexPage = ({ location }) => {
  const translation = {
    href: "/fr",
    hrefLang: "fr",
  }

  const data = useStaticQuery(graphql`
    query {
      heroBg: file(
        relativePath: { eq: "hero-bg.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        relativePath
        childImageSharp {
          fluid(maxWidth: 2000, maxHeight: 700, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      philosophyImage: file(
        relativePath: { eq: "philosophy.jpg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 640
            sizes: "(min-width: 1024px) 45vw, 100vw"
            quality: 85
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      teamImages: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativePath: {
            in: [
              "team/anne-gauthier-jaques.jpg"
              "team/nathanael-pittet.jpg"
              "team/blaise-luy.jpg"
              "team/cyril-jotterand.jpg"
              "team/yves-jobin.jpg"
              "team/michel-baumann.jpg"
              # "team/fabien-besson.jpg"
              "team/carolle-boillat.jpg"
            ]
          }
        }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(
                maxWidth: 304
                sizes: "(min-width: 1024px) 304px, 264px"
                quality: 85
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      pdf: file(
        relativePath: { eq: "ABLV-Group-Pledge-072020.pdf" }
        sourceInstanceName: { eq: "files" }
      ) {
        publicURL
      }
    }
  `)

  const heroImage = data.heroBg.childImageSharp

  const philosophyImage = data.philosophyImage.childImageSharp

  const teamImages = data.teamImages.edges.reduce((obj, item) => {
    return {
      ...obj,
      [item.node.relativePath]: item.node.childImageSharp,
    }
  }, {})

  const pdf = data.pdf

  return (
    <Layout lang="en" location={location} translation={translation}>
      <SEO
        title="ABLV | Share vision, ensure business prospects"
        description="Supporting the local economy by placing the entrepreneur at the forefront. The ABLV Group is a Swiss company with stakes in various companies."
      />

      {/* Hero */}
      <Hero
        uptitle="ABLV Group"
        title={`Share vision <br />Ensure business prospects`}
        image={heroImage}
      />

      {/* Présentation */}
      <section id="about">
        {/* Columns */}
        <div className="pt-20 -mt-20 c-container">
          <h2 className="uptitle">Introduction</h2>
          <div className="grid grid-cols-12 gap-4 lg:gap-8">
            {/* Column */}
            <div className="col-span-12 md:max-w-85% lg:max-w-none lg:col-span-6">
              <h1 className="text-3xl leading-tight md:text-4xl xl:text-6xl xxl:text-8xl">
                Supporting the local economy by placing the entrepreneur at the
                forefront
              </h1>
              <img
                className="max-w-70% mx-auto sm:mx-0 sm:max-w-50% my-16 lg:hidden"
                src={presentationImage}
                alt=""
              />
              <p className="mt-10 lg:text-lg">
                The ABLV Group is a Swiss company with stakes in various
                companies. Its core mission is to support the local economy by
                helping local companies to consolidate, as well as by supporting
                entrepreneurs. The Group brings to this task extensive
                professional skills in the building of sustainable companies,
                and financial resources allowing companies to envisage the
                future.
              </p>
              <p className="mt-10 lg:text-lg">
                Thanks to its strong Swiss foothold, the Group has excellent
                knowledge of the Swiss market, while working from its St-Sulpice
                headquarters in the Canton of Vaud.
              </p>
              {/* <div className="flex flex-row flex-wrap mt-16 stat-list lg:mt-24">
                <Stat
                  className="mb-4 mr-4 stat-list__item lg:mb-6 lg:mr-6"
                  number="2022"
                  label="Postes de travail"
                />
                <Stat
                  className="mb-4 mr-4 stat-list__item lg:mb-6 lg:mr-6"
                  number="52"
                  label="Spécialistes métiers"
                />
              </div> */}
            </div>
            {/* Column */}
            <div className="items-start justify-center hidden col-span-4 col-start-8 lg:flex">
              <img src={presentationImage} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* Philosophie */}
      <section id="philosophy">
        {/* Columns */}
        <div className="pt-20 -mt-20 c-container">
          <h2 className="uptitle">The ABLV group philosophy</h2>
          <div className="grid grid-cols-12 gap-4 lg:gap-8">
            {/* Column */}
            <div className="col-span-12 md:max-w-85% lg:max-w-none lg:col-span-5">
              <h1 className="text-3xl leading-tight md:text-4xl xl:text-6xl xxl:text-8xl">
                Every company is an ecosystem; our duty is to help make it
                sustainable
              </h1>
              <div className="mt-12 space-y-12 lg:mt-24 lg:space-y-24">
                <div>
                  <img className="mb-8" src={commonVisionImage} alt="" />
                  <h3 className="mb-4 text-xl font-bold md:text-2xl">
                    A shared vision
                  </h3>
                  <p className="lg:text-lg">
                    We only acquire stakes in businesses with which we are able
                    to build a shared vision while at the same time respecting
                    the existing corporate culture. Our involvement must enable
                    the consolidation and durability of the business or
                    fast-track its development.
                  </p>
                </div>
                <div>
                  <img className="mb-8" src={activeStakeImage} alt="" />
                  <h3 className="mb-4 text-xl font-bold md:text-2xl">
                    A minority but active stake
                  </h3>
                  <p className="lg:text-lg">
                    We believe that entrepreneurs are the cornerstones of their
                    businesses. We therefore actively work alongside them as
                    co-entrepreneurs – the goal being to consolidate the
                    business and ensure its sustainability. That is why we
                    usually acquire only minority stakes, thereby respecting the
                    equilibrium that made the company successful.
                  </p>
                </div>
                <div>
                  <img className="mb-8" src={longTermCommitmentImage} alt="" />
                  <h3 className="mb-4 text-xl font-bold md:text-2xl">
                    Investing in the long term
                  </h3>
                  <p className="lg:text-lg">
                    Our vision is long term, and our aim isn’t to sell our
                    stakes. We believe that working from solid foundations
                    ensures a company’s longevity and that taking a long-term
                    approach is one of the keys to success.
                  </p>
                </div>
                <div>
                  <h3 className="mb-4 text-xl font-bold md:text-2xl">
                    Document for download
                  </h3>
                  <a
                    href={pdf.publicURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button-icon button-icon--right"
                  >
                    The ABLV Group Pledge
                    <span className="button-icon__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="27"
                        viewBox="0 0 20 27"
                      >
                        <g
                          fill="none"
                          fillRule="evenodd"
                          stroke="#FFF"
                          strokeWidth="2"
                        >
                          <path d="M1.0047248 10.5050021L10.0022231 19.5l9.0025017-9M19 25.4977756H1M10 19.5V.5" />
                        </g>
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            {/* Column */}
            <div className="col-span-12 mt-12 lg:mt-0 lg:col-span-6 lg:col-start-7">
              <figure className="lg:text-right lg:sticky lg:top-6">
                <Img {...philosophyImage} />
                <figcaption className="mt-2 text-sm text-gray-200">
                  Photo by Ivan Bandura
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>

      {/* Compétences */}
      <section id="expertise">
        <div className="pt-20 -mt-20 c-container">
          <h2 className="col-span-12 uptitle">
            ABLV group’s areas of expertise
          </h2>
          <div className="grid grid-cols-12 col-gap-4 lg:gap-8 md:max-w-85% lg:max-w-none">
            <div className="col-span-12 lg:col-span-7">
              <h1 className="text-3xl leading-tight md:text-4xl xl:text-6xl xxl:text-8xl">
                Expertise and financial support that drives consolidation and
                development
              </h1>
              <p className="mt-10 lg:text-lg">
                The ABLV Group offers companies in the Group the opportunity to
                tap into both its financial strength and its network of experts,
                enabling them to expand effectively their own expertise.
              </p>
            </div>
          </div>
          <h3 className="mt-12 text-2xl font-bold lg:mt-24">
            The ABLV Group Team
          </h3>
          <div className="profile-list flex-wrap md:max-w-85% lg:max-w-none mt-8 flex flex-row">
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/anne-gauthier-jaques.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Anne Gauthier-Jaques</h4>
              <p className="mt-1 mb-4 text-sm ">Legal and PR</p>
              <a
                href="https://www.linkedin.com/in/anne-gauthier-jaques-8b082a32/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                View profile
              </a>
            </div>
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/nathanael-pittet.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Nathanaël Pittet</h4>
              <p className="mt-1 mb-4 text-sm">Business Management</p>
              <a
                href="https://www.linkedin.com/in/nathanaelpittet/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                View profile
              </a>
            </div>
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/blaise-luy.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Blaise Luy</h4>
              <p className="mt-1 mb-4 text-sm">
                Project and Process Management
              </p>
              <a
                href="https://www.linkedin.com/in/luyblaise/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                View profile
              </a>
            </div>
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/cyril-jotterand.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Cyril Jotterand</h4>
              <p className="mt-1 mb-4 text-sm">Engineering and Technology</p>
              <a
                href="https://www.linkedin.com/in/cyril-jotterand-59a34922/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                View profile
              </a>
            </div>
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/yves-jobin.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Yves Jobin</h4>
              <p className="mt-1 mb-4 text-sm ">
                Risk management and certifications
              </p>
              <a
                href="https://www.linkedin.com/in/yves-jobin/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                View profile
              </a>
            </div>
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/michel-baumann.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Michel Baumann</h4>
              <p className="mt-1 mb-4 text-sm">Sales Strategy</p>
              <a
                href="https://www.linkedin.com/in/michel-baumann-3a51b55/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                View profile
              </a>
            </div>
            {/* <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/fabien-besson.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Fabien Besson</h4>
              <p className="mt-1 mb-4 text-sm">Digital and Brand Strategy</p>
              <a
                href="https://www.linkedin.com/in/fabienbesson-superhuit/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                View profile
              </a>
            </div> */}
            <div className="flex flex-col items-start mb-16 mr-4 profile-list__item lg:mb-20 lg:mr-6">
              <Img
                className="self-stretch"
                {...teamImages["team/carolle-boillat.jpg"]}
              />
              <h4 className="mt-8 text-xl font-bold">Carole Boillat</h4>
              <p className="mt-1 mb-4 text-sm">
                Executive Assistant – Coordinator
              </p>
              <a
                href="https://www.linkedin.com/in/carole-boillat-5b371129/"
                target="_blank"
                rel="noopener noreferrer"
                className="button-icon button-icon--left button-icon--small"
              >
                <span className="button-icon__icon">
                  <img src={iconLinkedin} alt="" />
                </span>
                View profile
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
